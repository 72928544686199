import { withLayout } from '@/components/template/Layout'
import DeviceRegistration  from '@/components/template/Mfa/DeviceRegistration'
import React from 'react'

function MfaDeviceRegistration() {
    return (
        <>
            <DeviceRegistration
                displayMode={'default'}
            />
        </>
    )
}

export default withLayout(MfaDeviceRegistration)
